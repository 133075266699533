/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';
import { request } from '@/services/request';
import Alert from 'public/shared-icons/Alert.svg';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';

export const DeleteAppDialog = ({
  open,
  setOpen,
  title,
  name,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  name: string;
}) => {
  const route = useRouter();
  const [loading, setLoading] = useState(false);
  const { locale, messages } = useLanguage();
  const langData = messages?.DeleteAppDialog;

  const [radioSelected, setRadioSelected] = useState('delete only app');

  const handleDelete = async () => {
    if (radioSelected === 'delete only app') {
      try {
        setLoading(true);
        const response = await request.delete({ endpoint: `apps/${name}` });
        toast.success(langData?.application_deleted_successfully);
        route.push(`/${locale}/apps`);
        return response[0];
      } catch (error) {
        toast.error(langData?.error_in_deleting_application);
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    } else {
      console.log('Wrong value selected');
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="flex max-w-[639px] flex-col justify-between border-link-active bg-gradient-sky-dark p-8"
        aria-describedby={undefined}
      >
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 border-b border-line-primary pb-5 text-2xl font-normal">
            <Image src={Alert} alt="alert icon" className="h-8 w-8" />
            {langData?.delete_application}
          </DialogTitle>
        </DialogHeader>
        <div className="text-sm font-normal">
          <p>
            {langData?.are_you_sure_you_want_to_delete_the} “{title}”{' '}
            {langData?.application}?
          </p>
          <p className="mt-1">{langData?.this_action_cannot_be_undone}</p>

          <RadioGroup
            defaultValue="delete only app"
            className="mb-11 mt-6"
            onValueChange={(value) => setRadioSelected(value)}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem
                value="delete only app"
                id="r1"
                className={`bg-canvas-background-primary ${radioSelected === 'delete only app' && 'border-none bg-fill-button-rest'}`}
              />
              <Label htmlFor="r1" className="text-[13px] font-normal">
                {langData?.delete_application_but_keep_associated_data}
              </Label>
            </div>
            <div className="flex items-center space-x-2 opacity-60">
              <RadioGroupItem
                value="delete app and data"
                id="r2"
                className={`bg-canvas-background-primary ${radioSelected === 'delete app and data' && 'border-none bg-fill-button-rest'}`}
                disabled
              />
              <Label
                htmlFor="r2"
                className="flex items-center gap-2 text-[13px] font-normal"
              >
                <span>{langData?.delete_application_and_all_its_data}</span>
                <span className="-ml-0.5 rounded bg-[#EFDA1F] px-1.5 text-btn-label-sm text-[#07181E]">
                  {messages?.General?.wip}
                </span>
              </Label>
            </div>
          </RadioGroup>
        </div>

        <div className="flex items-center gap-3 border-t border-t-line-primary pt-5">
          <Button
            size="xs"
            type="submit"
            onClick={handleDelete}
            disabled={loading}
          >
            {messages?.General?.delete}
          </Button>

          <Button variant="outline" size="xs" onClick={() => setOpen(false)}>
            {messages?.General?.cancel}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
